<script lang="ts" setup>
import { getOverallPerformance, getOverallPerformanceCSV, useFileDownload } from '@/api/reports.api';
import { organisationStore } from '@/stores/organisation';
import { Skeleton, SkeletonLoader } from '@dailycare-au/ui'
import { chartOptions, registerChartComponents } from '@dailycare-au/visualise';
import { computedAsync } from '@vueuse/core';
import { CategoryScale, Chart, LinearScale, LineElement, PointElement } from 'chart.js';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { Line } from 'vue-chartjs'
import { useReportFilters } from './filters';

registerChartComponents(CategoryScale, LineElement, PointElement, LinearScale)

const { period } = useReportFilters()
const { organisation } = storeToRefs(organisationStore())

const loading = ref(false)
const data = computedAsync(async () => {
  const { data } = await getOverallPerformance(organisation.value!.id, period.value, 'this')
  return data
}, null, { evaluating: loading })

const { download } = useFileDownload(() => getOverallPerformanceCSV(organisation.value!.id, period.value, 'this'))

const options = chartOptions('line', {
  scales: {
    y: { ticks: { callback: (val, index, ticks) => `${val}%` } },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: ({ dataset, raw }) => `${dataset.label}: ${raw}%`
      }
    }
  }
})
const mappedData = computed(() => {
  if (!data.value) return { labels: [], datasets: [] }

  return {
    labels: data.value.scales.find(({ type }) => type === 'x')?.labels ?? [],
    datasets: data.value.datasets
  }
})
</script>

<template>
  <div class="w-full col-span-full mt-8">
    <div class="level">
      <h3 class="subtitle is-3 level-left mb-0">Organisation performance</h3>
      <div class="level-right">
        <app-action-button
          class="is-text gtm-download-user-performance"
          data-test="download-user-performance"
          @click="download"
        >
        <i class="fas fa-arrow-alt-to-bottom"></i>
        </app-action-button>
      </div>
    </div>
    <div class="h-[500px] w-full col-span-full mt-4">
    <SkeletonLoader :loading="loading">
      <Line :data="mappedData" :options="options" class="size-full" />

      <template #skeleton>
        <Skeleton class="size-full" />
      </template>
    </SkeletonLoader>
  </div>
  </div>
</template>