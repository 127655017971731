<script lang="ts" setup>
import { organisationStore } from '@/stores/organisation';
import { useProvideReportFilters } from './filters';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { userStore } from '@/stores/user';
import { format } from 'date-fns';

const { user } = storeToRefs(userStore())

const { period } = useProvideReportFilters()
const { organisation } = storeToRefs(organisationStore())

const organisationName = computed(() => organisation.value?.name ?? 'Organisation')

const lastUpdated = computed(() => format(new Date(), 'dd/MM/yyyy \'at 12:00am\''))
</script>

<template>
  <div class="native-reports">
    <div class="w-svw bg-dc-grey-100 text-dc-grey-800 py-14 p-6">
      <div class="container flex flex-col md:flex-row gap-8 items-baseline justify-between">
        <div>
          <h1 class="text-5xl">Welcome, {{ user.firstname }}</h1>
          <p class="mt-6">Report data last refreshed on <strong>{{ lastUpdated }}</strong></p>
        </div>

        <vacancies-reconfirm-btn :user-id="user.id" />
      </div>
    </div>

    <div class="section">
      <div class="container grid grid-cols-5 gap-4">
        <h3 class="subtitle is-1 col-span-full mb-0">{{organisationName}} overall performance</h3>
        <ReportsNativeCommonPeriodSelect v-model="period" />
        <ReportsNativeOverview />
        <ReportsNativeOverallPerformance />
        <ReportsNativePerformanceByHospital />
        <hr class="col-span-full my-16">
        <ReportsNativeFacility />
        <hr class="col-span-full my-16">
        <ReportsNativeUser />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  /**
   * unfortunately required to override bulmas forced utility classes
   * scoping also doesn't work here...
  */

  div.native-reports {
    .p-6 { padding: 1.5rem !important; }
    .pt-0 { padding-top: 0 !important; }
    .px-6 { padding-left: 1.5rem !important; padding-right: 1.5rem !important; }
    .mt-6 { margin-top: 1.5rem !important; }
    .py-14 { padding-top: 3.5rem !important; padding-bottom: 3.5rem !important; }

    hr {
      margin: 0;
    }
  }
</style>

<route lang="yaml">
meta:
  fullWidth: true
</route>