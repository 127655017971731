import { AxiosResponse } from 'axios';
import client from './client'
import { MaybeRefOrGetter, toValue } from 'vue';

const baseURL = '/access/reports'

export type Period = '7day' | '30day' | '90day' | '365day'
export type PeriodType = 'this' | 'last'

export interface Score {
  label:            string;
  description?:     string;
  value?:           number;
  previous_value?:  number;
  invert:           boolean;
  suffix?:          string;
}

export interface XYConfig {
  title:    string;
  scales:   Scale[];
  datasets: Dataset[];
}

export interface Dataset {
  label: string;
  data:  number[];
}

export interface Scale {
  type:   'x' | 'y';
  title?: string;
  labels?: string[];
}

export interface FacilityByHospital extends OrganisationByHospital {
  listingid: number;
  listingname: string;
}

export interface OrganisationByHospital {
  healthserviceid: number;
  healthservicename: string;
  hospitalid: number;
  hospitalname: string;
  referrals: number;
  referralsshare: string;
}


export interface ListingPerformance {
  listing_id:                number;
  listing_name:              string;
  avg_vacancy_update:        number;
  vacancy_updated:           number;
  page_one_appearances:      number;
  page_one_appearances_rank: number;
  referrals:                 number;
  referrals_rank:            number;
}

export interface UserPerformance {
  user_id:        number;
  user_name:      string;
  user_job_title: string;
  user_email:     string;
  user_status:    string;
  listing_count?:  number;
  last_login?:     Date;
  sessions_sum?:   number;
  sessions_days?:  number;
}

enum MimeTypes {
  ".csv" = "text/csv"
}

export function getReportOverview(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/overview/${periodType}/${period}`
  return client.get<Score[]>(url)
}

export function getOverallPerformance(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/performance/${periodType}/${period}`
  return client.get<XYConfig>(url)
}

export function getOverallPerformanceCSV(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/performance/${periodType}/${period}`
  return client.get<Blob>(url, { headers: { 'Accept': MimeTypes['.csv'] }})
}

export function getOverallPerformanceByHospital(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/hospital/performance/${periodType}/${period}`
  return client.get<OrganisationByHospital[]>(url)
}

export function getOverallPerformanceByHospitalCSV(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/hospital/performance/${periodType}/${period}`
  return client.get<Blob>(url, { headers: { 'Accept':  MimeTypes['.csv'] }})
}

export function getFacilityPerformanceByHospital(period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/facilities/hospital/performance/${periodType}/${period}`
  return client.get<FacilityByHospital[]>(url)
}

export function getFacilityPerformanceByHospitalCSV(period: Period = '7day', periodType: PeriodType = 'this', listingIds: number[] = []) {
  const url = `${baseURL}/facilities/hospital/performance/${periodType}/${period}`
  let params = { }
  if (listingIds.length > 0)
    params = {listings: listingIds.join(',')}
  return client.get<Blob>(url, { params: params, headers: { 'Accept': MimeTypes['.csv'] }})
}

export function getListingPerformance(period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/facilities/performance/${periodType}/${period}`
  return client.get<ListingPerformance[]>(url)
}

export function getListingPerformanceCSV(period: Period = '7day', periodType: PeriodType = 'this', listingIds: number[] = []) {
  const url = `${baseURL}/facilities/performance/${periodType}/${period}`
  let params = { }
  if (listingIds.length > 0)
    params = {listings: listingIds.join(',')}
  return client.get<Blob>(url, { params: params, headers: { 'Accept': MimeTypes['.csv'] }})
}

export function getUserPerformance(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/user/performance/${periodType}/${period}`
  return client.get<UserPerformance[]>(url)
}

export function getUserPerformanceCSV(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/user/performance/${periodType}/${period}`
  return client.get<Blob>(url, { headers: { 'Accept': MimeTypes['.csv'] }})
}


interface DownloadOptions {
  filename?: MaybeRefOrGetter<string>
}


export function useFileDownload(fn: () => Promise<AxiosResponse<Blob, any>>, options?: DownloadOptions) {

    const download = async () => {
        const response = await fn()
        var binaryData = [];
        binaryData.push(response.data);
        const filename = toValue(options?.filename) ?? response.headers["content-disposition"]?.split('filename=')[1]
        const objectUrl = window.URL.createObjectURL(new Blob(binaryData, { type: response.headers["Content-Type"]?.toString() || MimeTypes['.csv'] }))
        triggerDownload(objectUrl, filename)
        setTimeout(() => window.URL.revokeObjectURL(objectUrl), 10_000)
    }
    return { download }
}

function triggerDownload(objectUrl: string, filename?: string) {
  const link = document.createElement('a')
  link.href = objectUrl
  link.target = '_blank'
  link.rel = 'noopener noreferrer'

  if (filename)
    link.download = filename

  setTimeout(() => link.click())

  return link
}