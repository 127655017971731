<script lang="ts">
export default {
	name: "AppReportsHollistics"
};
</script>

<script lang="ts" setup>
import { userStore } from '@/stores/user';
import type { Report } from "@/types/dashboard";
import { storeToRefs } from 'pinia';

interface propsType {
	reports?: Report[];
}

const props = withDefaults(defineProps<propsType>(), {
	reports: () => []
});

const { user } = storeToRefs(userStore());

const dashboardFrameHeight = 5500;

const styles = {
	width: "100%",
	height: `${dashboardFrameHeight}px`
};
</script>

<template>
	<div class="md:flex mb-8 md:mb-0 justify-between items-center">
			<h3 class="title is-3">
				Welcome to DailyAccess, {{ user?.firstname }} {{ user?.lastname }}
			</h3>

			<vacancies-reconfirm-btn :user-id="user.id" />
		</div>

	<div v-for="({ name, url }, index) in reports" :key="index">
		<iframe :src="url" :title="name" frameborder="0" :style="styles"></iframe>
	</div>
</template>
