<script lang="ts">
export default {
	name: "AppReportsClassic"
};
</script>

<script lang="ts" setup>
import { userStore } from "@/stores/user";
import { storeToRefs } from "pinia";

import dashboards from "@/api/dashboard.api";
import { date_server } from "@/plugins/format";
import { format, sub } from "date-fns";
import { computed, onMounted, ref } from "vue";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import { authStore } from "@/stores/authentication";
import type { Shortcut, Range } from './helpers'
import { generateShortcuts } from './helpers'

const { user } = storeToRefs(userStore());
const { token } = storeToRefs(authStore());

const listingPerformance = ref();
const shortlistrefferals = ref();
const referralData = ref(true);
const startDate = ref<string | null>(
	date_server(sub(new Date(), { days: 365 }))
);
const endDate = ref<string | null>(date_server(new Date()));
const queryDates = ref(undefined);

const excelDownload = computed(() => {
	return [
		import.meta.env.VITE_APP_SERVICES,
		"metrics/users",
		user.value.id,
		`listingsperformanceextended.xlsx?jwt=${token.value}&start=${startDate.value}&end=${endDate.value}`
	].join("/");
});

const excelReferralDownload = computed(() => {
	return [
		import.meta.env.VITE_APP_SERVICES,
		"metrics/users",
		user.value.id,
		`shortlists.xlsx?jwt=${token.value}&start=${startDate.value}&end=${endDate.value}`
	].join("/");
});

// label for select shortcut / date range
const range = ref<string | null>(null);
const setSelectedShortcut = (shortcut: Shortcut) =>
	(range.value = shortcut.label);
const dateShortcuts = generateShortcuts(setSelectedShortcut);
const rangeLabel = computed(() => {
	if (range.value) return range.value;
	if (startDate.value && endDate.value) return `${format(new Date(startDate.value), 'MMM dd, yyyy')} - ${format(new Date(endDate.value), 'MMM dd, yyyy')}`;
	return "Select a date range";
});

const setRange = ({ start, end, shortcut }: Range) => {
	startDate.value = start;
	endDate.value = end;
	if (!shortcut) {
		range.value = null;
	}

	getTableData();
};

const getTableData = async () => {
	if (user.value) {
		const performanceData = await dashboards.listingsperformance(
			user.value.id,
			startDate.value,
			endDate.value
		);
		performanceData.data ??= [];
		listingPerformance.value = performanceData;

		const data = await dashboards.shortlistrefferals(
			user.value.id,
			startDate.value,
			endDate.value
		);
		data.data ??= [];
		shortlistrefferals.value = data;
	}
};

onMounted(() => {
	getTableData();
});
</script>

<template>
	<div class="md:flex mb-8 md:mb-0 justify-between items-center">
		<h3 class="title is-3">
			Welcome to DailyAccess, {{ user?.firstname }} {{ user?.lastname }}
		</h3>

		<vacancies-reconfirm-btn :user-id="user.id" />
	</div>

	<div class="dashboard-panel">
		<div class="level">
			<div class="level-left">
				<div>
					<h3 class="title is-4">
						<i class="fa fa-chart-line"></i>
						Listing performance
					</h3>
					<p class="subtitle uppercase">{{ rangeLabel }}</p>
				</div>
			</div>
			<div class="level-right">
				<div class="level-item w-64">
					<vue-ctk-date-time-picker
						v-model="queryDates"
						label="Select date range"
						format="YYYY-MM-DD"
						formatted="ll"
						input-size="sm"
						:custom-shortcuts="dateShortcuts"
						shortcut="last30Days"
						only-date
						range
						no-clear-button
						@update:model-value="setRange"
					/>
				</div>
				<div class="level-item">
					<a
						class="button is-small gtm-listing-performance-download-excel level-item"
						:href="excelDownload"
						target="_blank"
					>
						<i class="fa fa-download mr-2"></i>
						<span>Download Excel</span>
					</a>
				</div>
			</div>
		</div>
		<div class="legend mb-4">
			<strong>Legend: </strong>
			<span class="tag is-success mr-1">Within the top 25% of providers</span>
			<span class="tag is-warning mr-1">Within the 50-74% bracket</span>
			<span class="tag is-danger">Under 50% of providers within catchment</span>
		</div>
		<reports-table-listings-performance
			v-if="listingPerformance"
			:data="listingPerformance.data"
		></reports-table-listings-performance>
	</div>

	<hr />

	<div v-if="referralData" class="dashboard-panel">
		<div class="level">
			<div class="level-left">
				<div>
					<h3 class="title is-4">
						<i class="fa fa-retweet-alt"></i>
						Referrals
					</h3>
					<h4 class="subtitle">
						Every time one of your facilities was shortlisted by a social worker
					</h4>
				</div>
			</div>
			<div class="level-right">
				<a
					class="button is-small gtm-referrals-download-excel level-item"
					:href="excelReferralDownload"
					target="_blank"
				>
					<i class="fa fa-download mr-2"></i>
					<span>Download Excel</span>
				</a>
			</div>
		</div>
		<p class="subtitle uppercase">{{ rangeLabel }}</p>
		<reports-table-shortlist-referrals
			v-if="shortlistrefferals"
			:data="shortlistrefferals.data"
		></reports-table-shortlist-referrals>

		<!-- <table-referrals
			:data="referralData"
			:fetching="loadingReferral"
		></table-referrals> -->
	</div>
</template>

<style scoped>
.date-time-picker:deep(.field) {
	margin-bottom: 0;
}

.date-time-picker:deep(.custom-button) {
	@apply px-3 !important;
}
</style>
