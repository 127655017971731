<script lang="ts">

export interface ScoreColumnProps {
  value: number | null
  suffix?: string
  bands?: [number, number]
}
</script>

<script lang="ts" setup>
import { Badge, BadgeProps } from '@dailycare-au/ui';
import { computed } from 'vue';

const props = withDefaults(defineProps<ScoreColumnProps>(), {
  bands: () => [50, 75],
})

const badge = computed<{ label: string, variant: BadgeProps['variant'] }>(() => {
  const [low, medium] = props.bands
  const value = props.value
  if (value === null) return { label: 'N/A', variant: 'secondary' }

  if (value < low) return { label: 'low', variant: 'danger' }
  if (value < medium) return { label: 'medium', variant: 'warning' }

  return { label: 'high', variant: 'success' }
})
</script>

<template>
  <div class="flex items-center justify-between">
    <span v-if="value">{{ value.toFixed(0) }}{{ suffix }}</span>
    <span v-else>-</span>

    <Badge
      v-if="value"
      :variant="badge.variant"
      square
      size="sm"
      class="uppercase">{{ badge.label }}</Badge>
  </div>
</template>