<script lang="ts" setup>
import { Button, FieldControl, FieldItem, FieldLabel, FormField, SelectContent, SelectFooter, SelectItem, SelectItemText, SelectRoot, SelectTrigger, SelectValue } from '@dailycare-au/ui';
import { useProvideFacilityReportFilters } from './filters';
import { listingsStore as useListingsStore } from '@/stores/listings'
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

const { period, listingIds } = useProvideFacilityReportFilters()

const { listings } = storeToRefs(useListingsStore())

const selectedListings = computed(() => {
  return listings.value.filter(listing => listingIds.value.includes(listing.id))
})
</script>

<template>
  <section class="w-full col-span-full">
    <h3 class="subtitle is-1 col-span-full mb-4">Performance by facility</h3>
    <ReportsNativeCommonPeriodSelect v-model="period" />

    <FormField v-model="listingIds" name="facility_ids" v-slot="{ componentField }">
      <FieldItem inline class="w-2/5 items-center mb-16 mt-4">
        <span class="shrink-0">View by facility:</span>
        <FieldControl>
          <SelectRoot v-bind="componentField" multiple>
            <SelectTrigger>
              <SelectValue placeholder="All facilities" class="flex">
                <span v-if="selectedListings.length" class="truncate">{{ selectedListings[0].title }}</span>
                <span v-if="selectedListings.length > 1" class="text-dc-grey-500 shrink-0">&nbsp;+ {{ selectedListings.length - 1 }} more</span>
              </SelectValue>
            </SelectTrigger>

            <SelectContent class="ui-select-content z-[99]">
              <SelectItem v-for="listing in listings" :key="listing.id" :value="listing.id">
                <SelectItemText> {{ listing.title }} </SelectItemText>
              </SelectItem>

              <template #footer>
                <SelectFooter>
                  <button
                    class="text-dc-blue-400 hover:text-dc-blue-500 transition disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="!listingIds.length"
                    @click="listingIds = []"
                  >Select none</button>
                </SelectFooter>
              </template>
            </SelectContent>
          </SelectRoot>
        </FieldControl>
      </FieldItem>
    </FormField>

    <div class="space-y-16">
      <ReportsNativeFacilityPerformance />

      <ReportsNativeFacilityPerformanceByHospital />
    </div>
  </section>
</template>

<style lang="css">

.ui-select-content .px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>