<script lang="ts" setup>
import { useProvideUserReportFilters } from './filters';

const { period } = useProvideUserReportFilters()
</script>

<template>
  <section class="w-full col-span-full">
    <h3 class="subtitle is-1 col-span-full mb-4">Performance by user</h3>
    <ReportsNativeCommonPeriodSelect v-model="period" />

    <div class="space-y-16 mt-16">
      <ReportsNativeUserPerformance />
    </div>
  </section>
</template>
