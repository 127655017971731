<script lang="ts" setup>
import { createColumnHelper } from '@tanstack/vue-table'
import { Datatable } from '@dailycare-au/visualise';
import { computedAsync } from '@vueuse/core'
import { computed, ref } from 'vue'
import { getFacilityPerformanceByHospital, getFacilityPerformanceByHospitalCSV, FacilityByHospital, useFileDownload } from '@/api/reports.api'
import { useFacilityReportFilters } from './filters'


const helper = createColumnHelper<FacilityByHospital>()
const columns = [
  helper.accessor('listingname', { header: 'Facility' }),
  helper.accessor('healthservicename', { header: 'Health service' }),
  helper.accessor('hospitalname', { header: 'Hospital' }),
  helper.accessor('referrals', { header: 'Patient shortlists' }),
  helper.accessor('referralsshare', { header: 'Share of patient shortlists', sortingFn: 'alphanumeric'}),
]

const { period, listingIds } = useFacilityReportFilters()

const loading = ref(false)
const data = computedAsync(async () => {
  const { data } = await getFacilityPerformanceByHospital(period.value, 'this')
  return data
}, [], { evaluating: loading })

const { download } = useFileDownload(() => getFacilityPerformanceByHospitalCSV(period.value, 'this', listingIds.value))

const filteredListings = computed(() => {
  if (!data.value) return []
  if (!listingIds.value.length) return data.value

  return data.value.filter(({ listingid }) => listingIds.value.includes(listingid))
})
</script>

<template>
  <div>
    <div class="level">
      <h3 class="subtitle is-3 level-left mb-0">Facility performance by hospital</h3>
      <div class="level-right">
          <app-action-button
            class="is-text gtm-download-user-performance"
            data-test="download-user-performance"
            @click="download"
          >
          <i class="fas fa-arrow-alt-to-bottom"></i>
          </app-action-button>
      </div>
    </div>
    <Datatable :columns="columns" :data="filteredListings" />
  </div>
</template>