import { createInjectionState } from '@vueuse/core'
import { Period } from '@/api/reports.api'
import { ref } from 'vue'

const injectionKey = Symbol('userReportsFilters')
const [useProvideUserReportFilters, useInjectUserReportFilters] = createInjectionState(() => {
  const period = ref<Period>('7day')

  return { period }
}, { injectionKey })

function useUserReportFilters() {
  const filters = useInjectUserReportFilters()
  if (!filters) throw new Error('useUserReportFilters is called outside of the native reports')

  return filters
}

export { useProvideUserReportFilters, useUserReportFilters }